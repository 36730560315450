import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "release-notes"
    }}>{`Release Notes`}</h1>
    <ul>
      <li parentName="ul">{`Highly recommend making a backup copy of your current theme before updating to the newer version.`}</li>
      <li parentName="ul">{`Please note that if your theme files have been modified, it cannot be updated automatically. Please `}<a parentName="li" {...{
          "href": "/contact-us/"
        }}>{`contact us`}</a>{` for a quote on manual updates.`}</li>
    </ul>
    <h2 {...{
      "id": "711-02-21-2025"
    }}>{`7.1.1 (02-21-2025)`}</h2>
    <ul>
      <li parentName="ul">{`fix page's height when there are many category menus enabled fade effect`}</li>
      <li parentName="ul">{`update facebook sdk api`}</li>
      <li parentName="ul">{`remove deprecated variable `}<inlineCode parentName="li">{`settings.request.is_crawler`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "710-01-20-2025"
    }}>{`7.1.0 (01-20-2025)`}</h2>
    <ul>
      <li parentName="ul">{`[CORNERSTONE]`}{` Add nonce to scripts in checkout and account pages `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2525"
        }}>{`#2525`}</a></li>
      <li parentName="ul">{`[CORNERSTONE]`}{` Use fetch when updating variants in cart (`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2521"
        }}>{`#2521`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "709-01-10-2025"
    }}>{`7.0.9 (01-10-2025)`}</h2>
    <ul>
      <li parentName="ul">{`[CORNERSTONE]`}{` Fix product filter display name in Show More modal window `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2510"
        }}>{`#2510`}</a></li>
      <li parentName="ul">{`Fix compare products not working on home page`}</li>
      <li parentName="ul">{`Fix cache issue of shop by category banner images`}</li>
    </ul>
    <h2 {...{
      "id": "708-10-11-2024"
    }}>{`7.0.8 (10-11-2024)`}</h2>
    <ul>
      <li parentName="ul">{`Fix browser crash when editing options in Bulk Order layout with products that have an empty file`}</li>
      <li parentName="ul">{`Fix products by category not load products when clicking on the tabs`}</li>
    </ul>
    <h2 {...{
      "id": "august-2-2024---v707"
    }}>{`August 2, 2024 - v7.0.7`}</h2>
    <ul>
      <li parentName="ul">{`Fix price not display on homepage for logged in customer if hide price from guest is enabled`}</li>
      <li parentName="ul">{`Display login for pricing link on home page`}</li>
    </ul>
    <h2 {...{
      "id": "july-12-2024---v706"
    }}>{`July 12, 2024 - v7.0.6`}</h2>
    <ul>
      <li parentName="ul">{`Fix incorrect price symbol displayed in product cards generated by GraphQL`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Extended initialization interface of the storefront-account-payments lib, added theme styles `}{`[`}{`#2335`}{`]`}{`[`}<a parentName="li" {...{
          "href": "https://github.com/%5Bbigcommerce/cornerstone/pull/2335%5D(https://github.com/bigcommerce/cornerstone/pull/2335)%5C"
        }}>{`https://github.com/[bigcommerce/cornerstone/pull/2335](https://github.com/bigcommerce/cornerstone/pull/2335)\\`}</a>{`]`}</li>
    </ul>
    <h2 {...{
      "id": "june-21-2024---v705"
    }}>{`June 21, 2024 - v7.0.5`}</h2>
    <ul>
      <li parentName="ul">{`Fix Frequently Bought Together overlap product image on Safari`}</li>
      <li parentName="ul">{`Disable Instant-Load feature when viewing in Preview panel`}</li>
      <li parentName="ul">{`Support playing YouTube videos for homepage carousel on mobile`}</li>
    </ul>
    <h2 {...{
      "id": "may-3-2024---v704"
    }}>{`May 3, 2024 - v7.0.4`}</h2>
    <ul>
      <li parentName="ul">{`Fix show hover image and button on product cards generated by graphQL to respect theme settings`}</li>
      <li parentName="ul">{`Fix Instant-Load not display products by category on homepage`}</li>
    </ul>
    <h2 {...{
      "id": "april-26-2024---v703"
    }}>{`April 26, 2024 - v7.0.3`}</h2>
    <ul>
      <li parentName="ul">{`Fix remote banner: increase limit to 20`}</li>
      <li parentName="ul">{`Fix Add to Cart button appear along with Pre-order button`}</li>
      <li parentName="ul">{`Remove incorrect variable from GraphQL fetch products`}</li>
      <li parentName="ul">{`Fix price display on GraphQL product cards`}</li>
    </ul>
    <h2 {...{
      "id": "april-18-2024---v702"
    }}>{`April 18, 2024 - v7.0.2`}</h2>
    <ul>
      <li parentName="ul">{`Use GraphQL to display products by category and load more of featured/bestselling/new products`}</li>
    </ul>
    <h2 {...{
      "id": "march-29-2024---v701"
    }}>{`March 29, 2024 - v7.0.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix jstree hover color`}</li>
      <li parentName="ul">{`Set mute video on carousel by default`}</li>
      <li parentName="ul">{`Fix faceted filter price range not collapse by default`}</li>
      <li parentName="ul">{`Limit shop by brands strictly on the left sidebar`}</li>
      <li parentName="ul">{`Update arrow icon on standard menu consistent with other menu styles`}</li>
    </ul>
    <h2 {...{
      "id": "march-21-2024---v700"
    }}>{`March 21, 2024 - v7.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Update new header design`}</li>
      <li parentName="ul">{`Update Bulk Order layout support choosing product options`}</li>
      <li parentName="ul">{`Improve accessibility score`}</li>
      <li parentName="ul">{`Fix accessibility for advanced search`}</li>
      <li parentName="ul">{`Fix accessibility for main menu`}</li>
      <li parentName="ul">{`Make focus visible on product card image`}</li>
      <li parentName="ul">{`Fix color of the top banner link`}</li>
      <li parentName="ul">{`Update color of X icon on Modern style and border color of main menu on mobile`}</li>
      <li parentName="ul">{`Fix image flash when sliding to the next product image`}</li>
      <li parentName="ul">{`Update faceted filter toggle link on mobile`}</li>
      <li parentName="ul">{`Display loading for quick search, close button bigger on mobile`}</li>
      <li parentName="ul">{`Restore theme price`}</li>
    </ul>
    <h2 {...{
      "id": "february-2-2024---v652"
    }}>{`February 2, 2024 - v6.5.2`}</h2>
    <ul>
      <li parentName="ul">{`Update quick search to display results after typing 2 letters.`}</li>
      <li parentName="ul">{`Fix main image not display on PDP when BC encode HTML incorrect.`}</li>
      <li parentName="ul">{`Fix code typo.`}</li>
      <li parentName="ul">{`Fix category menu open on homepage but not close on other pages when Instant-Load is enabled.`}</li>
    </ul>
    <h2 {...{
      "id": "october-20-2023---v651"
    }}>{`October 20, 2023 - v6.5.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix scroll to top button not clickable when scrolling to the footer`}</li>
      <li parentName="ul">{`Change tab title of products by category sections`}</li>
    </ul>
    <h2 {...{
      "id": "august-01-2023---v650"
    }}>{`August 01, 2023 - v6.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix the sub menu item hidden in the 'more' menu item when auto-fit is enabled`}</li>
      <li parentName="ul">{`Fix edit cart item display incorrect message when product is unavailable / out of stock`}</li>
      <li parentName="ul">{`Fixed unavailable options' strikeout display during cart edit`}</li>
      <li parentName="ul">{`Fix cart quantity input change not working`}</li>
      <li parentName="ul">{`Fix to prevent mega menu from overflowing right edge of page`}</li>
      <li parentName="ul">{`Fix product schema snippet error when product has not description`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Removed accelerated checkout integration `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2341"
        }}>{`#2341`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added css classes for ApplePay Button `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2344"
        }}>{`[`}{`#2344`}{`]`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added styling config for the Bolt smart payment button `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2356"
        }}>{`[`}{`#2356`}{`]`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added style configs to payment buttons `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2361"
        }}>{`#2361`}</a>{` - PARTIAL MERGE due to limited file size, ignore file `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/commit/c2ab2d0828e8bb4debb1d251b13b05e67ce559a7#diff-9fcde326d127f74194f70e563bdf2c118c51b719c308f015b8eb0204a9a552fb"
        }}>{`schema.json`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Bump Stencil utils to 6.15.1 `}{`[`}{`#2365`}{`]`}{`[`}<a parentName="li" {...{
          "href": "https://github.com/%5Bbigcommerce/cornerstone/pull/2365%5D(https://github.com/bigcommerce/cornerstone/pull/2365)%5C"
        }}>{`https://github.com/[bigcommerce/cornerstone/pull/2365](https://github.com/bigcommerce/cornerstone/pull/2365)\\`}</a>{`]`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added ACH payment method section to My Account -> Payment Methods page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2362"
        }}>{`#2362`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Display fees on cart page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2376"
        }}>{`#2360`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Replace Twitter logo with X logo within social sharing and social link components `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2387"
        }}>{`#2387`}</a></li>
    </ul>
    <h2 {...{
      "id": "july-07-2023---v640"
    }}>{`July 07, 2023 - v6.4.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix homepage blank display when products by category are not visible for specific customers due to permission restrictions`}</li>
      <li parentName="ul">{`Fix Add to Cart button display for out of stock products in the recently viewed products section`}</li>
      <li parentName="ul">{`Fix SASS Compiler: stop using string +#{number} in function parameter`}</li>
      <li parentName="ul">{`Fix SCSS Compiler: product card icons`}</li>
      <li parentName="ul">{`Fix home page display blank when a category is not visible in products by categories section`}</li>
      <li parentName="ul">{`Use node 18`}</li>
      <li parentName="ul">{`Update Klarna logo`}</li>
      <li parentName="ul">{`Fix menu fit 1 line generating duplicated ID`}</li>
      <li parentName="ul">{`Fix mini popup not open again when adding to cart from product cards`}</li>
      <li parentName="ul">{`Fix quick payment button process incorrect product when frequently bought together enabled`}</li>
    </ul>
    <h2 {...{
      "id": "march-09-2023---v630"
    }}>{`March 09, 2023 - v6.3.0`}</h2>
    <ul>
      <li parentName="ul">{`Optimize schema remove some variable`}</li>
      <li parentName="ul">{`Fix glitch with product thumbnail arrows when video is enabled in gallery`}</li>
      <li parentName="ul">{`Fix logo size original on mobile`}</li>
      <li parentName="ul">{`Fix a glitch of searchInCategory in category page on mobile when the banner block above category appears`}</li>
      <li parentName="ul"><strong parentName="li">{`Update Cornerstone 6.9.0:`}</strong></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Make screen reader say error messages when editing customer account. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2233"
        }}>{`#2233`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Make screen reader say all errors then each error while tabbing. `}{`[`}{`#2230`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2230"
        }}>{`bigcommerce/cornerstone#2230`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` On customer message page, screen reader should say each error `}{`[`}{`#2234`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2234"
        }}>{`bigcommerce/cornerstone#2234`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` feat(payment): PAYPAL-1579 added wallet buttons component for product details and quick view`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Clarify customer order pagination. `}{`[`}{`#2241`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2241"
        }}>{`bigcommerce/cornerstone#2241`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add global region below the navigation in the header `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2231"
        }}>{`#2231`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Bump jQuery to 3.6.1. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2250"
        }}>{`#2250`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` feat(payment): PAYPAL-1474 added an ID for 'Check out' button and added extra container to render Accelerated Checkout in`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Reduce lodash usage `}{`[`}{`#2256`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2256"
        }}>{`bigcommerce/cornerstone#2256`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added styling config for the PayPal Accelerated Checkout button `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2254"
        }}>{`#2254`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Bump stencil utils with BODL events: Add to Cart and Remove from Cart `}{`[`}{`#2252`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2252"
        }}>{`bigcommerce/cornerstone#2252`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add unionpay img to be display on custumers account. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2260"
        }}>{`#2260`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add smart buttons attributes for various payment providers in Page builder `}{`[`}{`#2212`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2212"
        }}>{`bigcommerce/cornerstone#2212`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Migrate Cornerstone to new "Hide Price From Guests" functionality `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2262"
        }}>{`#2262`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add Accelerated buttons container into 'add to cart' popup on product details page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2264"
        }}>{`#2264`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Made PDP wallet buttons container hidden in cases when the product is not purchasable or out of stock `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2267"
        }}>{`#2267`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Updated PayPal Accelerated Checkout default button styles `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2268"
        }}>{`#2268`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add logic to collect Product Details data and send it to the BC App stencil template through custom event `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2270"
        }}>{`#2270`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fix the issue with getting product details data if the product details form is valid on page load `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2271"
        }}>{`#2271`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed escaping on created store account confirm message. `}{`[`}{`#2265`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2265"
        }}>{`bigcommerce/cornerstone#2265`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Delay validation on account signup, message form, and account edit page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2274"
        }}>{`#2274`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Set "Show quick payment buttons" setting to true by default `}{`[`}{`#2283`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2283"
        }}>{`bigcommerce/cornerstone#2283`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed en-CA translation warning in terminal. `}{`[`}{`#2278`}{`]`}{`[`}<a parentName="li" {...{
          "href": "https://github.com/%5Bbigcommerce/cornerstone/pull/2278%5D(https://github.com/bigcommerce/cornerstone/pull/2278)%5C"
        }}>{`https://github.com/[bigcommerce/cornerstone/pull/2278](https://github.com/bigcommerce/cornerstone/pull/2278)\\`}</a>{`]`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added manual captcha to Contact Us form for additional spam protection. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2290"
        }}>{`#2290`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed PDP not respecting "quantity box" display settings. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2291"
        }}>{`#2291`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added integrarion of storefront-account-payments lib `}{`[`}{`#2288`}{`]`}{`[`}<a parentName="li" {...{
          "href": "https://github.com/%5Bbigcommerce/cornerstone/pull/2288%5D(https://github.com/bigcommerce/cornerstone/pull/2288)%5C"
        }}>{`https://github.com/[bigcommerce/cornerstone/pull/2288](https://github.com/bigcommerce/cornerstone/pull/2288)\\`}</a>{`]`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fix styles for wallet buttons on PDP `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2285"
        }}>{`#2285`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add remote`}{`_`}{`api`}{`_`}{`scripts into cart/preview template to let GA3 snippet to fire the Product Added event, when clicking Add to cart button on Product detail page and rendering the response in popup. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2281"
        }}>{`#2281`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Refactored `}<inlineCode parentName="li">{`hide_price_from_guests`}</inlineCode>{` logic around `}<inlineCode parentName="li">{`show_cart_action`}</inlineCode>{` use `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2304"
        }}>{`#2304`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Removed all Google AMP template files `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2308"
        }}>{`#2308`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Customer order summary with both physical and digital items shows shipping as null `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2309"
        }}>{`#2309`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed product panels with scrolling/arrows prevent contextual menu on mobile devices `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2310"
        }}>{`#2310`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Webpack 5, Node 18 Support `}{`[`}{`#2311`}{`]`}{`[`}<a parentName="li" {...{
          "href": "https://github.com/%5Bbigcommerce/cornerstone/pull/2311%5D(https://github.com/bigcommerce/cornerstone/pull/2311)%5C"
        }}>{`https://github.com/[bigcommerce/cornerstone/pull/2311](https://github.com/bigcommerce/cornerstone/pull/2311)\\`}</a>{`]`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Remove xlink attributes on svg `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2322"
        }}>{`#2322`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` form.serialize() ignores dropdown option elements that have the disabled attribute `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2326"
        }}>{`#2326`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Extended BigCommerce.accountPayments app initialization interface `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2317"
        }}>{`#2317`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Submit Return Request button `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2331"
        }}>{`#2331`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Gift certificate CSS properties are applied to page after previewing Gift certificate on storefront `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2330"
        }}>{`#2330`}</a></li>
    </ul>
    <h2 {...{
      "id": "december-13-2022---v621"
    }}>{`December 13, 2022 - v6.2.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix theme updating issue.`}</li>
      <li parentName="ul">{`Fix 'blog`}{`_`}{`count' value type`}</li>
      <li parentName="ul">{`Fix duplicated font value`}</li>
      <li parentName="ul">{`Rename setting variables: fontSize-`}{`*`}{`, button-radius, carousel-bgColor-opacity, input-radius, container-border-radius, to change the variable types.`}</li>
    </ul>
    <h2 {...{
      "id": "december-9-2022---v620"
    }}>{`December 9, 2022 - v6.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix no-sidebar product template cause theme`}{`_`}{`settings.layout undefined`}</li>
      <li parentName="ul">{`Add new widget region blog`}{`_`}{`post`}{`_`}{`body`}</li>
      <li parentName="ul">{`Fix PayPal button (PaypalCommerceButton) overlap on the added to cart popup`}</li>
      <li parentName="ul">{`Add new Google fonts: Playfair Display, Montserrat, Josefin Sans. Optimize schema.json file.`}</li>
      <li parentName="ul">{`Update key render-blocking resources to be preloaded via HTTP headers/Early Hints `}{`[`}{`#2261`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2261"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/2261`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "october-13-2022---v612"
    }}>{`October 13, 2022 - v6.1.2`}</h2>
    <ul>
      <li parentName="ul">{`Change blog count option from 20 to 15.`}</li>
      <li parentName="ul">{`Fix in-category search not working when multiple search boxes are present.`}</li>
      <li parentName="ul">{`Fix shipping estimator's quotes alignment.`}</li>
      <li parentName="ul">{`Fix missing price in merchant listing schema. Fix long description in schema snippet.`}</li>
    </ul>
    <h2 {...{
      "id": "september-23-2022---v611"
    }}>{`September 23, 2022 - v6.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Hide Frequently Bought Together if all items are not purchasable`}</li>
      <li parentName="ul">{`Add theme setting: Blog > Posts per page`}</li>
      <li parentName="ul">{`Display the value of the selected swatch option when the PDP loads`}</li>
      <li parentName="ul">{`Update product schema json from Cornerstone`}</li>
      <li parentName="ul">{`Use browser-native lazyload for YouTube iframe videos`}</li>
    </ul>
    <h2 {...{
      "id": "august-26-2022---v610"
    }}>{`August 26, 2022 - v6.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix duplicate social sharing icons in the footer`}</li>
      <li parentName="ul">{`Improve Page Speed Optimization:`}<ul parentName="li">
          <li parentName="ul">{`Remove webpack vendors split chunk.`}</li>
          <li parentName="ul">{`Disable loading remote banners for search bots in speedtest optimization mode.`}</li>
          <li parentName="ul">{`Disable sharing buttons on PDP for search bots in speedtest optimization mode.`}</li>
          <li parentName="ul">{`Disable Frequently Bought Together for search bots in speedtest optimization mode.`}</li>
          <li parentName="ul">{`Disable loading third-party scripts for search bots in speedtest optimization mode.`}</li>
          <li parentName="ul">{`Fix Layout Shift of the product image carousel on PDP.`}</li>
          <li parentName="ul">{`Specify Width & Height of img tags of product cards, product listing items and product images.`}</li>
          <li parentName="ul">{`Add head-last block.`}</li>
          <li parentName="ul">{`Preload product main image on PDP.`}</li>
          <li parentName="ul">{`Not use lazyload+lqip for product image in write a review modal.`}</li>
        </ul></li>
      <li parentName="ul">{`Add theme setting Blog Style (Featured, 1 Column, 2 Columns)`}</li>
      <li parentName="ul">{`Revise design elements to make them more sophisticated and trendy`}</li>
      <li parentName="ul">{`Center logo on mobile`}</li>
    </ul>
    <h2 {...{
      "id": "august-16-2022---v600"
    }}>{`August 16, 2022 - v6.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix edit cart item not working`}</li>
      <li parentName="ul">{`Display min only if min equals max in bulk pricing table`}</li>
      <li parentName="ul">{`Fix product custom templates not displaying related products and front matter declaration`}</li>
      <li parentName="ul">{`Display qty box for PreOrder products on Bulk Order layout`}</li>
      <li parentName="ul">{`Correct product price format on Bulk Order layout`}</li>
      <li parentName="ul">{`Fix Add to Cart button of Bulk Order layout does not take into account quantity input after Cornerstone update`}</li>
      <li parentName="ul">{`Display all items in cart preview popup.`}</li>
      <li parentName="ul">{`Tweak Yellow style.`}</li>
      <li parentName="ul">{`Update Cornerstone 6.5.0:`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Scss lint has been replaced with stylelint. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2069"
        }}>{`#2069`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` BCTHEME-152 Replaced scss-lint with stylelint (#2069)(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2069"
        }}>{`bigcommerce/cornerstone#2069`}</a>{`)`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` User Account, bug styles in section payment methods. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2085"
        }}>{`#2085`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` 'undefined' is announced with screen reader while changing Product quantity on PDP. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2094"
        }}>{`#2094`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixing merge conflict: BCTHEME-606 `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2100"
        }}>{`#2100`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` "Manage Website Data Collection Preferences" phrase is not translatable. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2090"
        }}>{`#2090`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Empty email input in newsletter field should trigger an error. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2101"
        }}>{`#2101`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` As a shopper I want to see gift wrapping price in product list the cart. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2093"
        }}>{`#2093`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation mechanism for config.json has been updated. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2089"
        }}>{`#2089`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` (Partial Merge) Translation mechanism for config.json has been updated. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2089"
        }}>{`#2089`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Google AMP feature request - Add in release date info for preorder products. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2107"
        }}>{`#2107`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation for states select field on account signup page. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2105"
        }}>{`#2105`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added description field below payment provider name on "My Account" -> "Payment Methods" page. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2111"
        }}>{`#2111`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` additional checkout buttons don't work on applying 100% discount coupon. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2109"
        }}>{`#2109`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Changed debounce wait time for search to 1200 ms from 200 ms. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2115"
        }}>{`#2115`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Apply dependency updates (jest & lighthouse). `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2132"
        }}>{`#2132`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added basic validation for Account Signup Date Field when it's empty `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2126"
        }}>{`#2126`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Hide prices for aria-label and data-product-price attributes if set to "Hidden for guests". Hide currency selection for non-logged in users. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2131"
        }}>{`#2131`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Checkbox Option selection on Product. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2063"
        }}>{`#2063`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Replace schema microdata with LD+JSON tag. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2138"
        }}>{`#2138`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Optimized usage of jsTree library. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2145"
        }}>{`#2145`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Update stencil-utils package. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2157"
        }}>{`#2157`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Cornerstone performance optimizations: remove unused fonts. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2176"
        }}>{`#2176`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` (Partial Merge) Cornerstone performance optimizations: blocking scripts delaying DomContentLoaded. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2158"
        }}>{`#2158`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed password complexity error not displaying the complexity rules set in the store settings `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2117"
        }}>{`#2117`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Remove Compare Form. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2162"
        }}>{`#2162`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add google recaptcha to password reset request page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2164"
        }}>{`#2164`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` When price list price is set for currency, the cart does not respect product's price.`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2190"
        }}>{`#2190`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` (Partial Merge) Remove adminBar. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2191"
        }}>{`#2191`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Social media icons failing wave scan with "Empty Link". `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2202"
        }}>{`#2202`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Apply security updates (April 2022) `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2200"
        }}>{`#2200`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Stored Payment Methods form can be submitted without selecting a Country.`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2194"
        }}>{`#2194`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Show pickup details in storefront for BOPIS orders. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2199"
        }}>{`#2199`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` (Partial Merge) Incorrect handling of unsuccessful item removing from cart on the Cart page. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2211"
        }}>{`#2211`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add lang attribute to html tag to improve checkout accesibility `}{`[`}{`#2210`}{`]`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2210"
        }}>{`bigcommerce/cornerstone#2210`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Blog Pages Do Not Include Link Rel Next/Prev Tags. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2214"
        }}>{`#2214`}</a>{`.`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` feat(orders) ORDERS-4645 Add pickup to storefront order invoice`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` feat(orders) ORDERS-4645 Minor formatting update`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed "None" not being selected by default on unrequired Pick Lists without images `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2215"
        }}>{`#2215`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fixed The stored bank account was not displayed in the "payment methods" section `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2225"
        }}>{`#2225`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Incorrect translation key for Diners Club card type. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2237"
        }}>{`#2237`}</a>{`.`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Cannot Vault 16-digit Diners Club cards - creditcards module version is out of date `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/issues/2239"
        }}>{`#2239`}</a></li>
    </ul>
    <h2 {...{
      "id": "april-01-2022---v520"
    }}>{`April 01, 2022 - v5.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Added new region on the cart page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1901"
        }}>{`#1901`}</a></li>
      <li parentName="ul">{`Added custom event for product price change on PDP page. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1948"
        }}>{`#1948`}</a></li>
      <li parentName="ul">{`PAYPAL-886 added container setting for spb container. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2041"
        }}>{`#2041`}</a></li>
      <li parentName="ul">{`Workaround for PayPal Pay Later Message setting doesn't show Product price section`}</li>
      <li parentName="ul">{`New feature: Display videos in image gallery on PDP`}</li>
    </ul>
    <h2 {...{
      "id": "december-10-2021---v512"
    }}>{`December 10, 2021 - v5.1.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix Google Structured Data schema for product reviews - Invalid object type for field "author".`}</li>
      <li parentName="ul">{`Fix Recently Viewed Products dropdown position wrong when 'row' class exists (Foundation bug)`}</li>
      <li parentName="ul">{`Add class .sidebarBlock--recentlyViewed`}</li>
    </ul>
    <h2 {...{
      "id": "october-23-2021---v511"
    }}>{`October 23, 2021 - v5.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Revert SCSS for NodeJS 10 compatibility.`}</li>
    </ul>
    <h2 {...{
      "id": "october-22-2021---v510"
    }}>{`October 22, 2021 - v5.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix auto fit 1 line menu not display standard menu + check right edge more accurately.`}</li>
      <li parentName="ul">{`Fix HTML encoding in Address List + Edit Account pages`}</li>
      <li parentName="ul">{`Fix SCSS compile error in NodeJS v12`}</li>
      <li parentName="ul">{`[`}{`New Feature`}{`]`}{` Display Recently Viewed Products for everyone`}</li>
      <li parentName="ul">{`Remove recently viewed products limit`}</li>
    </ul>
    <h2 {...{
      "id": "september-09-2021---v502"
    }}>{`September 09, 2021 - v5.0.2`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` HTML Entity displayed as is via system/error message on a Storefront. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1888"
        }}>{`#1888`}</a></li>
      <li parentName="ul">{`End discount program.`}</li>
    </ul>
    <h2 {...{
      "id": "august-26-2021---v501"
    }}>{`August 26, 2021 - v5.0.1`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Apple pay button displaying needs to be fixed. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2043"
        }}>{`#2043`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Use `}{`[https:// for]`}{`(https:// for) schema markup. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2039"
        }}>{`#2039`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added styling config for the PayPal SPB on checkout page `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1866"
        }}>{`#1866`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added settings for payment banners. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2021"
        }}>{`#2021`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Delete from Cart confirmation popup. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2065"
        }}>{`#2065`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Gift Certificate -> Code required message. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2064"
        }}>{`#2064`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Compare page fields (Description, Rating and Availability). `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2059"
        }}>{`#2059`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Cart -> Shipping estimator error messages. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2066"
        }}>{`#2066`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Translation Gap: Account -> Wish List -> Name required message. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2060"
        }}>{`#2060`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add missing quotes for Country and State/Province fields of Shipping Estimator to pass functional tests `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2079"
        }}>{`#2079`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Added translations for Consent Manager. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2083"
        }}>{`#2083`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add pagination for Wishlists.`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1906"
        }}>{`#1906`}</a></li>
      <li parentName="ul">{`Remove instagram. Optimize schema/config. Optimize cssrelpreload.`}</li>
      <li parentName="ul">{`Fix Sort By not working when Instant-Load is enabled.`}</li>
      <li parentName="ul">{`Fix issue when selecting the search text from right to left by mouse`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` BCTHEME-304 add pagination for wishlists (`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1906"
        }}>{`bigcommerce/cornerstone#1906`}</a>{`)`}</li>
      <li parentName="ul">{`Discount 25%`}</li>
    </ul>
    <h2 {...{
      "id": "july-01-2021---v4100"
    }}>{`July 01, 2021 - v4.10.0`}</h2>
    <ul>
      <li parentName="ul">{`Move phrases and static strings to en.json for improving translation customizing. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1850"
        }}>{`#1850`}</a></li>
      <li parentName="ul">{`Bulk-Order: Hide progress bar when done`}</li>
      <li parentName="ul">{`Add option "Show all categories on sidebar"`}</li>
      <li parentName="ul">{`Improve CLS score on blog posts`}</li>
      <li parentName="ul">{`Add option show wishlist icon on the header.`}</li>
      <li parentName="ul">{`Convert some icons to SVG.`}</li>
      <li parentName="ul">{`Add product custom template no-addtocart.html`}</li>
    </ul>
    <h2 {...{
      "id": "may-06-2021---v490"
    }}>{`May 06, 2021 - v4.9.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix number field too small on product options`}</li>
      <li parentName="ul">{`Move Cookie Consent Update bar to bottom of page`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` BCTHEME-445 replace page builder ssl settings with new global region for html widget`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` fixed email address validation in forms. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/2029"
        }}>{`#2029`}</a></li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` fix(search): ES-2071 removed adding selected filters for price filter since not needed`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` BCTHEME-349 improve email validation for forms`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Remove AddThis for social sharing, replace with provider sharing links `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1997"
        }}>{`bigcommerce/cornerstone#1997`}</a></li>
      <li parentName="ul">{`Add bulk-order custom template for brand page`}</li>
      <li parentName="ul">{`Fix sale badge diagonal position`}</li>
      <li parentName="ul">{`Update price min max form spacing`}</li>
      <li parentName="ul">{`Update category and brand image optimized for Core Web Vitals CLS`}</li>
    </ul>
    <h2 {...{
      "id": "april-09-2021---v486"
    }}>{`April 09, 2021 - v4.8.6`}</h2>
    <ul>
      <li parentName="ul">{`Fix MPN not update when selecting product options`}</li>
      <li parentName="ul">{`Fix sub pages menu not expand on mobile`}</li>
      <li parentName="ul">{`Add theme option "Full width background color"`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` BCTHEME-269 Remove layout shifts to improve CLS indicator from Lighthouse performance report`}</li>
    </ul>
    <h2 {...{
      "id": "march-09-2021---v485"
    }}>{`March 09, 2021 - v4.8.5`}</h2>
    <ul>
      <li parentName="ul">{`Fixed BCTHEME-384 case when default option is out of stock add to cart button does not populate for in stock options. `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1955"
        }}>{`#1955`}</a></li>
      <li parentName="ul">{`Fixed STRF-8898 'Show More' gives no additional options when filter clicked. Bump stencil utils to 6.8.1.`}</li>
      <li parentName="ul">{`Fixed Choose Options button on Bulk Order layout not showing quick-view when ecommerce tracking is enabled.`}</li>
      <li parentName="ul">{`Added region product`}{`_`}{`below`}{`_`}{`rating on PDP.`}</li>
    </ul>
    <h2 {...{
      "id": "february-09-2021---v484"
    }}>{`February 09, 2021 - v4.8.4`}</h2>
    <ul>
      <li parentName="ul">{`Update vertical assignment`}</li>
    </ul>
    <h2 {...{
      "id": "february-02-2021---v483"
    }}>{`February 02, 2021 - v4.8.3`}</h2>
    <ul>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-2048`}{`]`}{` Supermarket 4.8.2 - Pagination on Search Result Page`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-2047`}{`]`}{` Supermarket 4.8.2 - Checkbox modifier rule doesn't reflect price`}</li>
      <li parentName="ul">{`Add theme option Carousel Video Autoplay`}</li>
      <li parentName="ul">{`Fix reviews pagination link not work when configured in tab`}</li>
      <li parentName="ul">{`Update theme vertical assignments`}</li>
    </ul>
    <h2 {...{
      "id": "december-19-2020---v482"
    }}>{`December 19, 2020 - v4.8.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix Faceted Filter not working at version 4.8.1`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` fix(storefront): STRF-8599 Drop Jquery: Replaced event's current target to the element passed from utils(on hook)`}</li>
    </ul>
    <h2 {...{
      "id": "december-15-2020---v481"
    }}>{`December 15, 2020 - v4.8.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix border of rectangle list using input border color`}</li>
      <li parentName="ul">{`Fix homepage carousel not full width in some case`}</li>
      <li parentName="ul">{`Add custom webpage template fullwidth`}</li>
      <li parentName="ul">{`Hide bulk pricing when Restrict to Login is set`}</li>
      <li parentName="ul">{`Fix some typo`}</li>
      <li parentName="ul">{`Fix blog page column misalignment`}</li>
      <li parentName="ul">{`Fix Fit Nav 1 Line moved Categories menu to right`}</li>
      <li parentName="ul">{`Removal Bulk Discounts Toggle from Store Settings`}</li>
      <li parentName="ul">{`Move blog`}{`_`}{`bottom region out page-content-body`}</li>
    </ul>
    <h2 {...{
      "id": "october-20-2020---v480"
    }}>{`October 20, 2020 - v4.8.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix Products By Category right column not hide when banner=no sub-category=hide`}</li>
      <li parentName="ul">{`Remove CSS transition of Sale badge`}</li>
      <li parentName="ul">{`Add regions for blog and blog-post pages`}</li>
      <li parentName="ul">{`Delete the old cookie warning script`}</li>
      <li parentName="ul">{`Add class sidebarBlock--shopByPrice to Shop by Price sidebar block`}</li>
      <li parentName="ul">{`[`}{`THEME-2003`}{`]`}{` Fix pagination display wrong total`}</li>
      <li parentName="ul">{`Fix currency switcher warning popup not show on mobile`}</li>
      <li parentName="ul">{`Add global regions`}</li>
    </ul>
    <h2 {...{
      "id": "july-03-2020---v470"
    }}>{`July 03, 2020 - v4.7.0`}</h2>
    <ul>
      <li parentName="ul">{`Display Klarna logo in payment icons`}</li>
      <li parentName="ul">{`Add font-display: swap to FontAwesome`}</li>
      <li parentName="ul">{`Fix home carousel first image not show up before slick init`}</li>
      <li parentName="ul">{`Fix Faceted filter not work with + and space param`}</li>
      <li parentName="ul">{`Update Also Bought for MQPO addon compatibility`}</li>
      <li parentName="ul">{`Display sidebar banners on category pages`}</li>
      <li parentName="ul">{`Fix search keyword show encoded string`}</li>
      <li parentName="ul">{`Add option alert-color-alt`}</li>
      <li parentName="ul">{`Add option search-in-category includes subcategory's products`}</li>
      <li parentName="ul">{`Fine tune product card info's style`}</li>
      <li parentName="ul">{`Fix the brand carousel wrong height`}</li>
      <li parentName="ul">{`Fix Products By Category image not show if subcategories = 0 & fine tune CSS for Modern style.`}</li>
      <li parentName="ul">{`Reduce site logo top/bottom spacing`}</li>
      <li parentName="ul">{`Add 20, 50 to related and customer also viewed products`}</li>
      <li parentName="ul">{`Fix the main navigation item icons break line`}</li>
      <li parentName="ul">{`Fix logo size incorrect on AMP`}</li>
      <li parentName="ul">{`Update Facebook SDK version 7.0`}</li>
      <li parentName="ul">{`Update stencil-cli v2.1.1`}</li>
      <li parentName="ul">{`Update Cornerstone 4.7.0`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Fix logo in admin bar`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Reset cart quantity to 0 if we get a 404 for the cart`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Add rel="noopener" to social links`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Bump lazysizes to 5.2.1`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Account: Product option special characters are not rendered on invoice, order contents and returns`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` CATALOG-5689 Special characters in the author field for product reviews are displaying html entities`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` INT-2593 Display wallet on drop-down section from cart icon`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` ES-1322 fixed the collapse and expand issue for custom filter without no special characters`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` STRF-7591 Storefront - “&” displays as “&” under account > orders > Ship/Bill to`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` STRF-7436 Line breaks entered in Return Instructions`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` `}{`[`}{`PERFORMANCE`}{`]`}{` Optimize polyfills`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` CATALOG-5557 Special characters are not rendered for product review`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` ES-466 Custom facets with & symbol works now`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` CHECKOUT-4828 Special characters to render properly on the cart modal pop up`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` Suppress YouTube 3rd party related videos`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` MCU-427 Display modal before switching currencies`}</li>
    </ul>
    <h2 {...{
      "id": "march-30-2020---v462"
    }}>{`March 30, 2020 - v4.6.2`}</h2>
    <ul>
      <li parentName="ul">{`Add region product`}{`_`}{`below`}{`_`}{`price`}</li>
      <li parentName="ul">{`Support main nav auto fit single line`}</li>
      <li parentName="ul">{`Fix indent of radio / checkbox product option on PDP`}</li>
      <li parentName="ul">{`Add regions: page`}{`_`}{`builder`}{`_`}{`content, home`}{`_`}{`below`}{`_`}{`new`}{`_`}{`products, home`}{`_`}{`below`}{`_`}{`featured`}{`_`}{`products, home`}{`_`}{`below`}{`_`}{`top`}{`_`}{`products`}</li>
      <li parentName="ul">{`[`}{`CORNERSTONE`}{`]`}{` fix(storefront): STRF-5678 fixed the check for max length in create account fields`}</li>
      <li parentName="ul">{`Update Cornerstone 4.5.0`}</li>
    </ul>
    <h2 {...{
      "id": "february-26-2020---v461"
    }}>{`February 26, 2020 - v4.6.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix products pick list image not display proper sizes`}</li>
      <li parentName="ul">{`Fix Sort By stop working if Facted Search enabled.`}</li>
      <li parentName="ul">{`Update stencil-utils 5.0.3 and remove the fixes for old stencil-utils`}</li>
      <li parentName="ul">{`Fix Facebook Page block not reload if Instant-Load enabled`}</li>
      <li parentName="ul">{`Add border to the quick search box in Modern style`}</li>
      <li parentName="ul">{`Fix Facebook share icon not break line properly on mobile`}</li>
    </ul>
    <h2 {...{
      "id": "february-19-2020---v460"
    }}>{`February 19, 2020 - v4.6.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix top bottom banners not change when InstantLoad enabled`}</li>
      <li parentName="ul">{`Fix 2 banners next to the main carousel dropped the line`}</li>
      <li parentName="ul">{`Fix Non Sale Price label not display. Improve prices display on PDP.`}</li>
      <li parentName="ul">{`Fix category menu display twice on the search page has faceted filter`}</li>
      <li parentName="ul">{`Fix "Search in Category" is clear when press enter`}</li>
      <li parentName="ul">{`Fix "Search in Category" clear all filters also clear search`}</li>
      <li parentName="ul">{`Fix newsletter popup form overflow on Firefox`}</li>
      <li parentName="ul">{`Add Bulk Order option to Products display type`}</li>
      <li parentName="ul">{`Add DIV tag to wrap brand description`}</li>
      <li parentName="ul">{`Fix incorrect category image and brand image size`}</li>
      <li parentName="ul">{`Fix Popular Brands limit on PDP and Brand page`}</li>
      <li parentName="ul">{`Fix Popular Brands sidebar block on Brand page showing as configured`}</li>
      <li parentName="ul">{`Improve Sticky Header support for the whole header`}</li>
      <li parentName="ul">{`Fix category image on Bulk Order template`}</li>
      <li parentName="ul">{`Fix thumbnail image responsive on cart page`}</li>
      <li parentName="ul">{`Fine tune spacing on cart page on mobile`}</li>
      <li parentName="ul">{`Fix Faceted Search XHR doesn't work after the first click`}</li>
      <li parentName="ul">{`Fix back button doesn't clear the search input in category page`}</li>
      <li parentName="ul">{`Fix Faceted Filter option title wrong encoded character %, "`}</li>
      <li parentName="ul">{`Update Cornerstone 4.4.0:`}<ul parentName="li">
          <li parentName="ul">{`Fixes and some additions to our Google Structured Data schema for the product page template`}</li>
          <li parentName="ul">{`MERC-3688: Fix thumbnail image sizes`}</li>
          <li parentName="ul">{`Added missing gift certificate translation`}</li>
          <li parentName="ul">{`Fix registerValidation function to validate on writeReview-form email field `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1585"
            }}>{`#1585`}</a></li>
          <li parentName="ul">{`Fix typo using `}<inlineCode parentName="li">{`page`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`pages`}</inlineCode></li>
          <li parentName="ul">{`Fixes body text color not taking effect for cart item headings on mobile / tablet `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1586"
            }}>{`#1586`}</a></li>
          <li parentName="ul">{`chore: bumping stencil-cli forward for node 10 support`}</li>
          <li parentName="ul">{`Add alert/success components on customer address list page.`}</li>
          <li parentName="ul">{`Fix styling of review modal image `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1592"
            }}>{`#1592`}</a></li>
          <li parentName="ul">{`Fix corejs warning `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1594"
            }}>{`#1594`}</a></li>
          <li parentName="ul">{`Fix cart quantity calculation for bundled products `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1596"
            }}>{`#1596`}</a></li>
          <li parentName="ul">{`Upgrade core-js to version 3 `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1598"
            }}>{`#1598`}</a></li>
          <li parentName="ul">{`Add jquery-migrate to Modal test `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1599"
            }}>{`#1599`}</a></li>
          <li parentName="ul">{`STRF-7604 - Fix remaining itemprop availability tag for pricing`}</li>
          <li parentName="ul">{`Fix to make browser back button navigate pagination properly when faceted search is enabled `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1606"
            }}>{`#1606`}</a></li>
          <li parentName="ul">{`Allow alert text color editing from theme editor and update default alert text color for Bold variation `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1565"
            }}>{`#1565`}</a></li>
          <li parentName="ul">{`Update sweetalert2 library to latest version `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1617"
            }}>{`#1617`}</a></li>
          <li parentName="ul">{`Resolve visual regression in error modal icon animation `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1619"
            }}>{`#1619`}</a></li>
          <li parentName="ul">{`Cannot add wishlist with item when logged out `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1618"
            }}>{`#1618`}</a></li>
          <li parentName="ul">{`ES-98 Product filters configured to not display product count show a count when you use the "More" modal`}</li>
          <li parentName="ul">{`Add translation key for "read more" blog post link `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1625"
            }}>{`#1625`}</a></li>
          <li parentName="ul">{`Update My Account Payment Methods template to expose all new savable payment methods `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1603"
            }}>{`1603`}</a></li>
          <li parentName="ul">{`Proper address formatting without any additional commas in My Account Payment Methods `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1626"
            }}>{`1626`}</a></li>
          <li parentName="ul">{`Product filters configured to not display product count show a count when you use the "More" modal`}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1622"
            }}>{`1622`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "november-11-2019---v452"
    }}>{`November 11, 2019 - v4.5.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1893`}{`]`}{` Largest srcset image being picked by browser for PDP image, which causes it to load slowly`}</li>
      <li parentName="ul">{`Fix flyout menu broken when set style = Modern, layout = Default`}</li>
      <li parentName="ul">{`Fix spacing of sub menu items when Style = Modern and Showing sub-categories vertical = Yes`}</li>
      <li parentName="ul">{`Support Retina logo`}</li>
    </ul>
    <h2 {...{
      "id": "november-6-2019---v451"
    }}>{`November 6, 2019 - v4.5.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix vertical subcategories menu broken in Modern style in version 4.5.0.`}</li>
      <li parentName="ul">{`Add footer.scripts to order confirmation page`}</li>
      <li parentName="ul">{`Do not load main image srcset if product image responsive is disabled`}</li>
      <li parentName="ul">{`Fix JS error when input only 1 category ID in Products By Category setting in version 4.5.0.`}</li>
      <li parentName="ul">{`Improve restoring original image when switching no image variant`}</li>
    </ul>
    <h2 {...{
      "id": "november-1-2019---v450"
    }}>{`November 1, 2019 - v4.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix rich snippets on AMP product pages`}</li>
      <li parentName="ul">{`Fix social bookmark not work when InstantLoad enable`}</li>
      <li parentName="ul">{`Fix InstantLoad call duplicated requests on the pagination links when Faceted filters enabled.`}</li>
      <li parentName="ul">{`New feature "Search in Category" on category pages. Turn on/off this feature in Theme Editor > Products > Show quick search in category.`}</li>
      <li parentName="ul">{`Fix AMP add to cart button not work on Safari iOS in some case.`}</li>
      <li parentName="ul">{`Improve products by category allow to load form any sub-categories, and load more products.`}</li>
      <li parentName="ul">{`Update Featured Bestselling New products tabs to support load more, improve loading speed.`}</li>
      <li parentName="ul">{`Add search in category for bulk-order template`}</li>
      <li parentName="ul">{`Fix HTML validator`}</li>
      <li parentName="ul">{`Add new feature to display banners on Mega Menu`}</li>
      <li parentName="ul">{`Add option to on/off responsive main product images`}</li>
      <li parentName="ul">{`Tweak CSS padding for static pages in Modern style`}</li>
      <li parentName="ul">{`Remove unused settings product`}{`_`}{`uniform`}{`_`}</li>
    </ul>
    <h2 {...{
      "id": "september-16-2019---v440"
    }}>{`September 16, 2019 - v4.4.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix cart image overflow.`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1880`}{`]`}{` Sale Badges overlap Side Navigation when Compact Style used.`}</li>
      <li parentName="ul">{`Improve "Frequently Bought Together" add selected to cart button, hide thumbnails of products cannot be purchased.`}</li>
      <li parentName="ul">{`Fix Menu icon not show on AMP on Chrome.`}</li>
      <li parentName="ul">{`Add Google Pay icon.`}</li>
      <li parentName="ul">{`Show View Cart button on the mini popup after added to cart.`}</li>
      <li parentName="ul">{`Show Cart-Level discounted price on added to cart popup.`}</li>
      <li parentName="ul">{`Remove margin of product custom fields when in table.`}</li>
      <li parentName="ul">{`Update Cornerstone 4.1.1:`}<ul parentName="li">
          <li parentName="ul">{`Reset compare products counter after faceted search updates page content `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1571"
            }}>{`#1571`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "august-30-2019---v430"
    }}>{`August 30, 2019 - v4.3.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix THEME-1706 Image Coming Soon does not display on product page.`}</li>
      <li parentName="ul">{`Fix THEME-1764 Header webpage dropdown overlay is behind 2nd row links`}</li>
      <li parentName="ul">{`Fix THEME-1813 Footer > Brand Theme Editor display count setting ignored on storefront`}</li>
      <li parentName="ul">{`Fix JS error when having only 1 image in PDP`}</li>
      <li parentName="ul">{`Fix product thumbnails break line when the carousel hasn't finished initialization.`}</li>
      <li parentName="ul">{`Improve home main carousel to allow smaller image.`}</li>
      <li parentName="ul">{`Update Cornerstone 4.1.0:`}<ul parentName="li">
          <li parentName="ul">{`deleted whitespaces in if statments(content.html) `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1560"
            }}>{`#1560`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "august-16-2019---v421"
    }}>{`August 16, 2019 - v4.2.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix product images distorted in version 4.2.0`}</li>
      <li parentName="ul">{`Fix videos layout break if not show in tab`}</li>
      <li parentName="ul">{`Fix the main carousel autoplay issue occurred in version 4.2.0`}</li>
    </ul>
    <h2 {...{
      "id": "august-12-2019---v420"
    }}>{`August 12, 2019 - v4.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix out of stock message on PDP.`}</li>
      <li parentName="ul">{`Fix product faceted filters error when having special characters.`}</li>
      <li parentName="ul">{`Show MPN.`}</li>
      <li parentName="ul">{`Rename Bought Together button to "Select all".`}</li>
      <li parentName="ul">{`Fix social sharing urls.`}</li>
      <li parentName="ul">{`Fix Product badges on list item.`}</li>
      <li parentName="ul">{`Fix multiple videos on the main carousel not play.`}</li>
      <li parentName="ul">{`Optimize JS.`}</li>
      <li parentName="ul">{`Fix price prop for Google structured data (rich text).`}</li>
      <li parentName="ul">{`Fix review link not work in InstantLoad.`}</li>
      <li parentName="ul">{`Add option to show Videos in tabs and re-style videos layout.`}</li>
      <li parentName="ul">{`Fix breadcrumb spacing on search result page.`}</li>
      <li parentName="ul">{`PRODUCTS COMPARE: Improve products compare feature.`}</li>
      <li parentName="ul">{`Fix JS error on product page on IE.`}</li>
      <li parentName="ul">{`Fix setProductVariant error when product name contains (") character.`}</li>
      <li parentName="ul">{`Fine tune CSS for mobile.`}</li>
      <li parentName="ul"><strong parentName="li">{`Update Cornerstone 4.0.0`}</strong>{`:`}<ul parentName="li">
          <li parentName="ul">{`Explicitly disable autocomplete in password entry input fields. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1465"
            }}>{`#1465`}</a></li>
          <li parentName="ul">{`Sanitize faceted search titles to remove HTML `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1426"
            }}>{`#1426`}</a></li>
          <li parentName="ul">{`Fix submenu click on mobile navigation `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1472"
            }}>{`#1472`}</a></li>
          <li parentName="ul">{`Fix dropdown cart not showing discounts `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1481"
            }}>{`#1481`}</a></li>
          <li parentName="ul">{`Fix to open Bulk Pricing modal from Quick View. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1483"
            }}>{`#1483`}</a></li>
          <li parentName="ul">{`Update default layout settings for PayPal smart buttons `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1486"
            }}>{`#1486`}</a></li>
          <li parentName="ul">{`Update jQuery to 3.4.1 `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1502"
            }}>{`#1502`}</a></li>
          <li parentName="ul">{`Remove remote`}{`_`}{`api`}{`_`}{`scripts to avoid double firing analytics `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1505"
            }}>{`#1505`}</a></li>
          <li parentName="ul">{`Update license year `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1510"
            }}>{`#1510`}</a></li>
          <li parentName="ul">{`Remove Node 6 from Travis `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1511"
            }}>{`#1511`}</a></li>
          <li parentName="ul">{`Clean up whitespace in `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1513"
            }}>{`#1513`}</a></li>
          <li parentName="ul">{`Add additional regions `}{`[`}{`#1491`}{`]`}{` (`}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1491"
            }}>{`bigcommerce/cornerstone#1491`}</a>{`)`}</li>
          <li parentName="ul">{`Fix conditional logic in returns-list.html `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1512"
            }}>{`#1512`}</a></li>
          <li parentName="ul">{`Fix conditional logic in share.html `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1522"
            }}>{`#1522`}</a></li>
          <li parentName="ul">{`Update @babel/polyfill to 7.4.4 `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1521"
            }}>{`#1521`}</a></li>
          <li parentName="ul">{`Add maxlength to text options `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1531"
            }}>{`#1531`}</a></li>
          <li parentName="ul">{`Add return instructions in return-saved.html `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1525"
            }}>{`#1525`}</a></li>
          <li parentName="ul">{`Remove Google Plus `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1526"
            }}>{`#1526`}</a></li>
          <li parentName="ul">{`Fix broken conditional statement in share.html `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1533"
            }}>{`#1533`}</a></li>
          <li parentName="ul">{`Enable Facebook like button to be displayed on product page if enabled `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1530"
            }}>{`#1530`}</a></li>
          <li parentName="ul">{`Remove nanobar (loading bar) `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1537"
            }}>{`#1537`}</a></li>
          <li parentName="ul">{`Fix incorrect DOM selectors causing the payment method form on the account page from working properly in Safari `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1541"
            }}>{`#1541`}</a></li>
          <li parentName="ul">{`Remove head.rsslinks `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1539"
            }}>{`#1539`}</a></li>
          <li parentName="ul">{`Utilize srcset for responsive images `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1507"
            }}>{`#1507`}</a></li>
          <li parentName="ul">{`Clean up conditional logic in a couple component templates `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1547"
            }}>{`#1547`}</a></li>
          <li parentName="ul">{`Remove "demo" product conditional logic `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1551"
            }}>{`#1551`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "april-16-2019---v410"
    }}>{`April 16, 2019 - v4.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix faceted search encoding URL`}</li>
      <li parentName="ul">{`Fix product image padding on add to cart popup of Modern style`}</li>
      <li parentName="ul">{`Fix related products price changed`}</li>
      <li parentName="ul">{`Fix AMP add to cart error`}</li>
      <li parentName="ul">{`Fix schema.json validation`}</li>
      <li parentName="ul">{`Rename product template from default to with-sidebar`}</li>
      <li parentName="ul">{`Fix JS error in AMP iframe`}</li>
      <li parentName="ul">{`Allow clients to select their desired number of brands on the sidebar`}</li>
      <li parentName="ul">{`Fix button's posittion on 3 product columns`}</li>
      <li parentName="ul">{`Fix newsletter form style in the footer`}</li>
    </ul>
    <h2 {...{
      "id": "april-1-2019---v400"
    }}>{`April 1, 2019 - v4.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Add option "Layout" of product page (default or no-sidebar).`}</li>
      <li parentName="ul">{`Add option "Show subcategories menu on sidebar".`}</li>
      <li parentName="ul">{`Add option "Show categories" (Vertical in 'Categories' item or Horizontal).`}</li>
      <li parentName="ul">{`Add new feature "Instant Load"`}</li>
      <li parentName="ul">{`Fix card buttons not show on list mode`}</li>
      <li parentName="ul">{`Fix Also Bought Together product price changed`}</li>
      <li parentName="ul">{`Fix out of stock message show up when Also Bought product is out stock`}</li>
      <li parentName="ul">{`Add extra CSS class for tabs on product page`}</li>
      <li parentName="ul">{`Active vertical category menu on the sidebar for product pages`}</li>
      <li parentName="ul">{`Add theme setting Main navigation > Animation`}</li>
      <li parentName="ul">{`Fine tune CSS mainly for Modern style.`}</li>
      <li parentName="ul">{`Increase image size of product list option`}</li>
      <li parentName="ul">{`Add option to Card Text Align.`}</li>
      <li parentName="ul">{`Add option featured, new, bestselling products limit up to 50.`}</li>
      <li parentName="ul">{`Add option products by category limit up to 100.`}</li>
      <li parentName="ul">{`Add option Style: "Compact".`}</li>
      <li parentName="ul">{`Major update for Yellow style: new colors, compact style, smaller font sizes.`}</li>
      <li parentName="ul">{`Show selected value of Swatch-type product option.`}</li>
      <li parentName="ul">{`Move brand below rating on product cards.`}</li>
      <li parentName="ul">{`Expose jQuery as global variable "window.jQuerySupermarket".`}</li>
      <li parentName="ul">{`Add option allow to pull Bought Together products from related products or customer also bought products.`}</li>
      <li parentName="ul">{`Allow restore product main image after select an option.`}</li>
      <li parentName="ul">{`Remove vertical slide down menu on the header.`}</li>
      <li parentName="ul">{`Add option to show Add Recently viewed products on the sidebar.`}</li>
      <li parentName="ul">{`Move Subpages block to the left sidebar`}</li>
      <li parentName="ul">{`Remove Sticky sidebar & content on category pages.`}</li>
      <li parentName="ul">{`Add option to show product detailed info on product list mode`}</li>
      <li parentName="ul">{`Improve styling of products listing column on mobile`}</li>
      <li parentName="ul">{`Show a short tab's content on mobile devices`}</li>
      <li parentName="ul">{`Scroll to viewport after collapse content`}</li>
      <li parentName="ul">{`Optimize loading remote banners & display banners on quick view`}</li>
      <li parentName="ul">{`Add option to hide loading bar at the top of page`}</li>
      <li parentName="ul">{`Add schema markup for MPN, GTIN`}</li>
    </ul>
    <h2 {...{
      "id": "february-15-2019---v311"
    }}>{`February 15, 2019 - v3.1.1`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Update Cornerstone 3.2.1:`}</strong><ul parentName="li">
          <li parentName="ul">{`Product description in Compare no longer shows escaped HTML. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1439"
            }}>{`#1439`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1439"
            }}></a>{`Removed href="#" from quick view button. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1445"
            }}>{`#1445`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1445"
            }}></a>{`Removed href="#" from quick view list item. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1446"
            }}>{`#1446`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "february-11-2019---v310"
    }}>{`February 11, 2019 - v3.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix static banner glitch after BC changed banner content wrapped by a DIV element.`}</li>
      <li parentName="ul">{`Fix to show only 1 loading progress bar on the top of page.`}</li>
      <li parentName="ul">{`Revert "Remove deprecated fields..." `}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1407"
        }}>{`#1407`}</a>{` to continue supporting configurable fields`}</li>
      <li parentName="ul">{`Fix add to cart not show popup if GA auto tag enabled`}</li>
      <li parentName="ul"><strong parentName="li">{`Update Cornerstone 3.2.0:`}</strong><ul parentName="li">
          <li parentName="ul">{`Ensure SKU and UPC display correctly for Variants on PDP. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1431"
            }}>{`#1431`}</a></li>
          <li parentName="ul">{`Don't fire Cart API request if there is no cart `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1402"
            }}>{`#1402`}</a></li>
          <li parentName="ul">{`Cleanup and XSS fix on Cart page. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1434"
            }}>{`#1434`}</a></li>
          <li parentName="ul">{`Correcting ID's in date.html form-fields`}</li>
          <li parentName="ul">{`Fix for product without image on Compare page. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1438"
            }}>{`#1438`}</a></li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Update Cornerstone master:`}</strong><ul parentName="li">
          <li parentName="ul">{`Added package-lock.json. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1441"
            }}>{`#1441`}</a></li>
        </ul></li>
      <li parentName="ul">{`Improve PageSpeed score.`}</li>
    </ul>
    <h2 {...{
      "id": "january-24-2019---v300"
    }}>{`January 24, 2019 - v3.0.0`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`[`}{`NEW`}{`]`}</strong>{` Product image sticky on product page`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`[`}{`NEW`}{`]`}</strong>{` Add new feature Also Bought (Bought Together) on product pages.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fix hide special custom fields in compare page.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fix hide empty addition tab.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Improve feature banner below header.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Support expand/close content of custom tabs.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fix Bulk Order add to cart when customer login is required.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fix login for price link`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add custom class for custom field rows.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add CSS class for cart-total.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Update to Conerstone 3.0.0`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make display of quantity selection box on PDP configurable. `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1398"
          }}>{`#1398`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove unnecessary API call to get cookie notification status `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1380"
          }}>{`#1380`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cart switch from quote item hash to id which is immutable `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1387"
          }}>{`#1387`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove extra font only used for textual store logo. `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1375"
          }}>{`#1375`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`shotaK's Add context to the menu collapsible factory target elements `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1382"
          }}>{`#1382`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added default rule for product carousel card title to break words on overflow. `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1389"
          }}>{`#1389`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Only show cookie privacy notice for EU IP addresses `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1381"
          }}>{`#1381`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Move Cart Quantity header value to a FE API call `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1379"
          }}>{`#1379`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added defer tag to addThis and defered execution of related script `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1406"
          }}>{`#1406`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove deprecated fields - delivery and event date, and configurable fields. `}<a parentName="p" {...{
            "href": "https://github.com/bigcommerce/cornerstone/pull/1407"
          }}>{`#1407`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Update to Cornerstone 3.1.0`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Fix for ESLint "func-names" warnings. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1420"
            }}>{`#1420`}</a></li>
          <li parentName="ul">{`Major performance improvements. Reduce Javascript bundle size from 376kb to 286kb. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1390"
            }}>{`#1390`}</a></li>
          <li parentName="ul">{`Send GA tracking event whenever the last product is removed from the CART`}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1409"
            }}>{`#1409`}</a></li>
          <li parentName="ul">{`Fix cart item quantity change rollback `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1418"
            }}>{`#1418`}</a></li>
          <li parentName="ul">{`Changed z-index to higher for header `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1422"
            }}>{`#1422`}</a></li>
          <li parentName="ul">{`Removed customer (not address) phone number requirement from Edit Account `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1417"
            }}>{`#1417`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Update to Cornerstone 3.1.1`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Downgrade Webpack to last known good version during development. `}<a parentName="li" {...{
              "href": "https://github.com/bigcommerce/cornerstone/pull/1428"
            }}>{`#1428`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "december-05-2018---v280"
    }}>{`December 05, 2018 - v2.8.0`}</h2>
    <ul>
      <li parentName="ul">{`Add 2 banners beside the main carousel`}</li>
      <li parentName="ul">{`Add brands page sorted by newest`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1700`}{`]`}{` Carousel image links do not function correctly in IE`}</li>
      <li parentName="ul">{`Add Bulk Order feature.`}</li>
    </ul>
    <h2 {...{
      "id": "november-12-2018---v271"
    }}>{`November 12, 2018 - v2.7.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix the bundle error of 2.7.0`}</li>
    </ul>
    <h2 {...{
      "id": "november-07-2018---v270"
    }}>{`November 07, 2018 - v2.7.0`}</h2>
    <ul>
      <li parentName="ul">{`Add support for Paypal smart buttons settings `}{`[`}{`#1359`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1359"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1359`}</a>{`)`}</li>
      <li parentName="ul">{`Allow to configure homepage sections`}</li>
      <li parentName="ul">{`MERC-4097 Copy changes, rearrange Payment Buttons section`}</li>
      <li parentName="ul">{`Fix spacing with SweetAlert cancel button on mobile `}{`[`}{`#1363`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1363"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1363`}</a>{`)`}</li>
      <li parentName="ul">{`Copy changes for Payment Buttons section. `}{`[`}{`#1365`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1365"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1365`}</a>{`)`}</li>
      <li parentName="ul">{`Product review modal error message is now accurate. `}{`[`}{`#1370`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1370"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1370`}</a>{`)`}</li>
      <li parentName="ul">{`Add support for Card Management: List, Delete, Edit, Add and Default Payment Method `}{`[`}{`#1376`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1376"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1376`}</a>{`)`}</li>
      <li parentName="ul">{`Add support for declarative data tag analytics. `}{`[`}{`#1377`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1377"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1377`}</a>{`)`}</li>
      <li parentName="ul">{`Update base theme Cornerstone 2.6.0`}</li>
    </ul>
    <h2 {...{
      "id": "october-02-2018---v260"
    }}>{`October 02, 2018 - v2.6.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix textarea cannot start new line when hit enter`}</li>
      <li parentName="ul">{`Show Add to Cart & Compare buttons on mobile`}</li>
      <li parentName="ul">{`Add link to order status in account menu when viewing order `}{`[`}{`#1343`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1343"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1343`}</a>{`)`}</li>
      <li parentName="ul">{`Fixed amp page error related to store logo `}{`[`}{`#1323`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1323"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1323`}</a>{`)`}</li>
      <li parentName="ul">{`Blueprint for Mapping Custom Templates to JavaScript Modules `}{`[`}{`#1346`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1346"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1346`}</a>{`)`}</li>
      <li parentName="ul">{`Fix option set. Selection from option set that containes multiple similar options. `}{`[`}{`1347`}{`]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1347"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1347`}</a>{`)`}</li>
      <li parentName="ul">{`Update lazysizes plugin to 4.1.2 `}{`[`}{`#1358`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1358"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1358`}</a>{`)`}</li>
      <li parentName="ul">{`Stop lazyloading store logo `}{`[`}{`#1357`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1357"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1357`}</a>{`)`}</li>
      <li parentName="ul">{`Added hidden field for checkboxes with a "No" value. `}{`[`}{`#1355`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1355"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1355`}</a>{`)`}</li>
      <li parentName="ul">{`Add div and id attributes so that contact form steps can be tracked `}{`[`}{`#1317`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1317"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1317`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "september-11-2018---v252"
    }}>{`September 11, 2018 - v2.5.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1661`}{`]`}{` Product images load constantly when first image in list is not set to thumbnail`}</li>
    </ul>
    <h2 {...{
      "id": "september-07-2018---v251"
    }}>{`September 07, 2018 - v2.5.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix popup preview cart CSS error appear in v2.5.0`}</li>
    </ul>
    <h2 {...{
      "id": "september-07-2018---v250"
    }}>{`September 07, 2018 - v2.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix faceted fitlers if filter name has special character`}</li>
      <li parentName="ul">{`Fix Facebook Like icon hide Pinterest icon`}</li>
      <li parentName="ul">{`Fix banner images do not show when specify Cat IDs for products by categories section`}</li>
      <li parentName="ul">{`Fix breadcrumb schema`}</li>
      <li parentName="ul">{`Add theme option Flyout Menu`}</li>
      <li parentName="ul">{`Fix blog`}{`_`}{`post import statement in app.js `}{`[`}{`#1301`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1301"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1301`}</a>{`)`}</li>
      <li parentName="ul">{`Corrects mini cart display issues `}{`[`}{`#1298`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1298"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1298`}</a>{`)`}</li>
      <li parentName="ul">{`Fix invoice store logo. `}{`[`}{`#1326`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1326"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1326`}</a>{`)`}</li>
      <li parentName="ul">{`Require Webpack config only when used (reduce time to be ready for receiving messages from stencil-cli). `}{`[`}{`#1334`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1334"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1334`}</a>{`)`}</li>
      <li parentName="ul">{`Fix encoding issues on Account Signup Form ("'" characters showing in country name)`}{`[`}{`#1341`}{`]`}{` (`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1341"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1341`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "august-02-2018---v240"
    }}>{`August 02, 2018 - v2.4.0`}</h2>
    <ul>
      <li parentName="ul">{`Add new feature allow to specify categories to display products on homepage`}</li>
      <li parentName="ul">{`Update QuickSearch to support ajax results on mobile`}</li>
      <li parentName="ul">{`Fix for ESLint "quotes" and "quote-props" errors. `}{`[`}{`#1280`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1280"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1280`}</a>{`)`}</li>
      <li parentName="ul">{`Fix cart link not being clickable on mobile when white space reduced around store logo `}{`[`}{`#1296`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1296"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1296`}</a>{`)`}</li>
      <li parentName="ul">{`Open correct product page tabs when URL contains a fragment identifier referring to that content `}{`[`}{`#1304`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1304"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1304`}</a>{`)`}</li>
      <li parentName="ul">{`Hide popup product option swatch on mobile`}</li>
      <li parentName="ul">{`Display product reviews in tabbed content region of product page. `}{`[`}{`#1302`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1302"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1302`}</a>{`)`}</li>
      <li parentName="ul">{`Show bulk discounts only if enabled through store settings. `}{`[`}{`#1310`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1310"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1310`}</a>{`)`}</li>
      <li parentName="ul">{`Style active section in search results. `}{`[`}{`#1316`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1316"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1316`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "july-02-2018---v230"
    }}>{`July 02, 2018 - v2.3.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix logo footer not center on mobile`}</li>
      <li parentName="ul">{`[`}{`THEME-1614`}{`]`}{` Fix Facebook Like button not appear`}</li>
      <li parentName="ul">{`Fix typo class name productView-info-value--upc`}</li>
      <li parentName="ul">{`Fix not show product special tabs when no tabs checked`}</li>
      <li parentName="ul">{`Add main brand logo size configuration`}</li>
      <li parentName="ul">{`Fix AMP category page error: span no href`}</li>
      <li parentName="ul">{`Fix empty image on carousel wrap. `}{`[`}{`#1263`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1263"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1263`}</a>{`)`}</li>
      <li parentName="ul">{`Fix duplicate IDs occurrence in product options in certain situations & syntax fix in bulk-discount-rates component `}{`[`}{`#1223`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1223"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1223`}</a>{`)`}</li>
      <li parentName="ul">{`Fix use case that prevented retail/sale prices from displaying on product details page `}{`[`}{`#1262`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1262"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1262`}</a>{`)`}</li>
      <li parentName="ul">{`Fix svg arrows missing on AMP product pages. `}{`[`}{`#1258`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1258"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1258`}</a>{`)`}</li>
      <li parentName="ul">{`Fix arrow placement on currency dropdown menu `}{`[`}{`#1267`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1267"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1267`}</a>{`)`}</li>
      <li parentName="ul">{`Add alias for lazysizes module to bundle minified library `}{`[`}{`#1275`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1275"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1275`}</a>{`)`}</li>
      <li parentName="ul">{`Fix prices not showing in quick search while logged in when "Restrict to Login" for price display is true `}{`[`}{`#1272`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1272"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1272`}</a>{`)`}</li>
      <li parentName="ul">{`Fix duplicate input ID's in product review form `}{`[`}{`#1276`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1276"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1276`}</a>{`)`}</li>
      <li parentName="ul">{`Fix Webpack DefinePlugin configuration. `}{`[`}{`#1286`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1286"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1286`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "june-21-2018---v220"
    }}>{`June 21, 2018 - v2.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Fix footer logo align left`}</li>
      <li parentName="ul">{`Fix checkout buttons alignment`}</li>
      <li parentName="ul">{`Fix footer popular brands break new line on Safari`}</li>
      <li parentName="ul">{`Fix bulk price table not show for Price Lists`}</li>
      <li parentName="ul">{`Show SKU on cart page and dropdown preview cart`}</li>
      <li parentName="ul">{`Fix AMP store logo and revert STRF-4804 as it cause invalid CSS`}</li>
      <li parentName="ul">{`Fix swatch not display tooltip`}</li>
      <li parentName="ul">{`Fix site logo size`}</li>
      <li parentName="ul">{`Fix default brand logo not display`}</li>
      <li parentName="ul">{`Fix video on the main carousel not play`}</li>
      <li parentName="ul">{`Styling video block on product page for Modern style`}</li>
      <li parentName="ul">{`Add new setting to display mini modal or hide modal after added to cart`}</li>
      <li parentName="ul">{`Fix add to cart on AMP product page`}</li>
    </ul>
    <h2 {...{
      "id": "june-01-2018---v210"
    }}>{`June 01, 2018 - v2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add Newsletter summary section to subscription form. `}{`[`}{`#1248`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1248"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1248`}</a>{`)`}</li>
      <li parentName="ul">{`Show retail price range with strikethrough. `}{`[`}{`#1199`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1199"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1199`}</a>{`)`}</li>
      <li parentName="ul">{`Fix quantity edit on Simple Product AMP pages. `}{`[`}{`#1242`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1242"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1242`}</a>{`)`}</li>
      <li parentName="ul">{`Fix for individual low inventory count for SKUs. `}{`[`}{`#1234`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1234"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1234`}</a>{`)`}</li>
      <li parentName="ul">{`Avoid undefined context in WishList instance. `}{`[`}{`#1247`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1247"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1247`}</a>{`)`}</li>
      <li parentName="ul">{`Update code from Cornerstone 2.1.0`}</li>
      <li parentName="ul">{`Add image lazyload`}</li>
    </ul>
    <h2 {...{
      "id": "may-29-2018---v200"
    }}>{`May 29, 2018 - v2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Update improvements from Cornerstone 2.0.0`}</li>
      <li parentName="ul">{`Performance improvements. `}{`[`}{`#1229`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1229"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1229`}</a>{`)`}</li>
      <li parentName="ul">{`Fix Product Options hiding Add to Cart on a Google AMP page `}{`[`}{`#1214`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1214"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1214`}</a>{`)`}</li>
      <li parentName="ul">{`Hide blank review stars when there are no reviews on a product `}{`[`}{`#1209`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1209"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1209`}</a>{`)`}</li>
      <li parentName="ul">{`Fix for excess whitespace in multiline text field product option `}{`[`}{`#1222`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1222"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1222`}</a>{`)`}</li>
      <li parentName="ul">{`Fix for sort disappearing on range update with product filtering `}{`[`}{`#1232`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1232"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1232`}</a>{`)`}</li>
      <li parentName="ul">{`Fix logo image dimensions on AMP pages. `}{`[`}{`#1239`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1239"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1239`}</a>{`)`}</li>
      <li parentName="ul">{`Fix product pricing schema.org microdata. `}{`[`}{`#1233`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1233"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1233`}</a>{`)`}</li>
      <li parentName="ul">{`Removed unused browserlist. `}{`[`}{`#1241`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1241"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1241`}</a>{`)`}</li>
      <li parentName="ul">{`Fix for ESLint "no-console" warning. `}{`[`}{`#1237`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1237"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1237`}</a>{`)`}</li>
      <li parentName="ul">{`fix(catalog): CATALOG-2913 individual low inventory count for skus`}</li>
    </ul>
    <h2 {...{
      "id": "may-24-2018---v155"
    }}>{`May 24, 2018 - v1.5.5`}</h2>
    <ul>
      <li parentName="ul">{`Show custom badge on product page`}</li>
      <li parentName="ul">{`Fix: Support redirect to cart page after added to cart was removed by mistake.`}</li>
      <li parentName="ul">{`Fix category description has no padding on Modern style`}</li>
      <li parentName="ul">{`Add theme option allow to display category description on AMP`}</li>
      <li parentName="ul">{`Fix currency label display wrong on AMP. Change color of menu toggle icon on AMP.`}</li>
      <li parentName="ul">{`Fix product thumbnails carousel when image count < image to show`}</li>
      <li parentName="ul">{`Fix vertical subcategories 3-level not expand automatically`}</li>
    </ul>
    <h2 {...{
      "id": "may-18-2018---v154"
    }}>{`May 18, 2018 - v1.5.4`}</h2>
    <ul>
      <li parentName="ul">{`Fix multiple videos cannot play`}</li>
      <li parentName="ul">{`Fix a glitch of price with tax on product page`}</li>
      <li parentName="ul">{`Update image size of blog bigger`}</li>
      <li parentName="ul">{`Support menu to display 4th+ level categories`}</li>
      <li parentName="ul">{`Fix styling price on product page when Include Tax is selected`}</li>
    </ul>
    <h2 {...{
      "id": "may-10-2018---v153"
    }}>{`May 10, 2018 - v1.5.3`}</h2>
    <ul>
      <li parentName="ul">{`Fix add to wishlist dropdown css on Firefox medium screen`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1512`}{`]`}{` Titles of product tabs include in description structured data`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1540`}{`]`}{` Product images disappear when first image in list is not set to thumbnail`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1557`}{`]`}{` Unable to close store search overlay when 0 results are returned`}</li>
      <li parentName="ul">{`Fix `}{`[`}{`THEME-1595`}{`]`}{` Ordered Lists are truncated on left side of Product Description`}</li>
      <li parentName="ul">{`Improve style of advanced search categories jstree`}</li>
      <li parentName="ul">{`Improve style of price on product page better`}</li>
    </ul>
    <h2 {...{
      "id": "may-04-2018---v152"
    }}>{`May 04, 2018 - v1.5.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix product thumbnails carousel should not have variable width`}</li>
      <li parentName="ul">{`Fix product image disappear on cart page on tablet`}</li>
    </ul>
    <h2 {...{
      "id": "may-03-2018---v151"
    }}>{`May 03, 2018 - v1.5.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix no results quick search popup cannot close`}</li>
    </ul>
    <h2 {...{
      "id": "may-03-2018---v150"
    }}>{`May 03, 2018 - v1.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Shows price ranges instead of prices when they are present in the context on product list pages `}{`[`}{`#1111`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1111"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1111`}</a>{`)`}</li>
      <li parentName="ul">{`Remove "as low as" feature and add support for price ranges instead`}{`[`}{`#1143`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1143"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1143`}</a>{`)`}</li>
      <li parentName="ul">{`CATALOG-2408 Fix updateView firing when there are no default options `}{`[`}{`#1172`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1172"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1172`}</a>{`)`}</li>
      <li parentName="ul">{`Add representation for products and variants with retail price that has sale price. `}{`[`}{`#1195`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1195"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1195`}</a>{`)`}</li>
      <li parentName="ul">{`Add the +/- icons for the category filtering `}{`[`}{`#1211`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1211"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1211`}</a>{`)`}</li>
      <li parentName="ul">{`Add head.scripts reference to checkout & order`}{`_`}{`confirmation pages `}{`[`}{`#1158`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1158"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1158`}</a>{`)`}</li>
      <li parentName="ul">{`Fix image dimensions on AMP pages. `}{`[`}{`#1192`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1192"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1192`}</a>{`)`}</li>
      <li parentName="ul">{`Add schema microdata for breadcrumbs. `}{`[`}{`#1175`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1175"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1175`}</a>{`)`}</li>
      <li parentName="ul">{`Add support for per-variant bulk pricing tier display on PDP `}{`[`}{`#1167`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1167"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1167`}</a>{`)`}</li>
      <li parentName="ul">{`Implements Add to any Wish Lists capability. `}{`[`}{`#1134`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1134"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1134`}</a>{`)`}</li>
      <li parentName="ul">{`Fix slick-next and slick-prev so that they are centered across all screen sizes. `}{`[`}{`#1166`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1166"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1166`}</a>{`)`}</li>
      <li parentName="ul">{`Fixes functionality of date picker option on product pages. `}{`[`}{`#1125`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1125"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1125`}</a>{`)`}</li>
      <li parentName="ul">{`Add image width & height for carousel images. `}{`[`}{`#1126`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1126"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1126`}</a>{`)`}</li>
      <li parentName="ul">{`STENCIL-3962 Use `}{`_`}{`.includes rather than `}{`_`}{`.contains`}</li>
      <li parentName="ul">{`Adds a theme editor display toggle for weight and dimensions on product pages `}{`[`}{`#1092`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1092"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1092`}</a>{`)`}</li>
      <li parentName="ul">{`Upgrades all dependencies except for Foundation and jQuery `}{`[`}{`#1069`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1069"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1069`}</a>{`)`}</li>
      <li parentName="ul">{`Add footer script to optimized checkout / order confirmation `}{`[`}{`#1085`}{`]`}{`(`}<a parentName="li" {...{
          "href": "https://github.com/bigcommerce/cornerstone/pull/1085"
        }}>{`https://github.com/bigcommerce/cornerstone/pull/1085`}</a>{`)`}</li>
      <li parentName="ul">{`Fix quick search icon hide on other variations`}</li>
      <li parentName="ul">{`Fix price styling on product page`}</li>
      <li parentName="ul">{`Hide newsletter popup image on mobile`}</li>
      <li parentName="ul">{`Fix quick view product on product page cannot select options`}</li>
    </ul>
    <h2 {...{
      "id": "april-25th-2018---v143"
    }}>{`April 25th, 2018 - v1.4.3`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add ability to mute sound on video on the main carousel`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` swatch option cannot change on iPad landscape mode`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add option to display SKU on product card`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` cutting text on cart form select box`}</li>
      <li parentName="ul"><strong parentName="li">{`SALE OFF 20%`}</strong>{` for limited time`}</li>
    </ul>
    <h2 {...{
      "id": "april-4th-2018---v142"
    }}>{`April 4th, 2018 - v1.4.2`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix logo & product image stretched on AMP pages`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix Add to Cart button does not work if product have no option on AMP page`}</li>
    </ul>
    <h2 {...{
      "id": "march-29th-2018---v141"
    }}>{`March 29th, 2018 - v1.4.1`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add theme option to on/off changing product image when hover`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix missing font size, outstock badge color in Theme Editor`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` fix(storefront): STENCIL-3567 Fix spaces in faceted search option names`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Update styling Main Carousel for Modern style`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix store-logo image at footer not valid AMP`}</li>
    </ul>
    <h2 {...{
      "id": "march-22th-2018---v140"
    }}>{`March 22th, 2018 - v1.4.0`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix padding of banners search box on mobile`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Hide brand images for layout A-Z Table`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Update AMP compatible with Supermarket theme`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add new variation Modern`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add option to display Out Of Stock badge`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Display remove icon on dropdown mini cart`}</li>
    </ul>
    <h2 {...{
      "id": "march-7th-2018---v130"
    }}>{`March 7th, 2018 - v1.3.0`}</h2>
    <ul>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Don't show homepage carousel video until it is loaded`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix expand styling on default layout - vertical dropdown sub menus checked`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Add more space on default layout for breadcumbs and sidebar`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix product option checkbox not change price`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix top banner padding`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix welcome text color not change`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Show products by category even category has no products`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add options to set active tab, show/hide tabs in products by category blocks`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix G+, FB social icons messy on product page`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add options number of products, subcategories display on products by category blocks`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Collapse popular brands on sidebar on mobile`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Tweak container padding, search form on 404 page, heading align & spacing`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Change checkout button to primary on cart page`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` update modernizr.js to 3.5.0`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix product options 3 columns not work on chrome/android`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Hide quick-view text on product listing on mobile`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` reduce space in product listing item`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` blog page show categories menu like other pages`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add option to display 4 categories in top categories list section on homepage`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Improve advanced search to sidebar`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Faster loading homepage carousel`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Center product main image while page is still loading`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix product page horizontal slider when the first image is not default`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Improve image thumbnails better size on product page`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Fix print order on checkout confirmation page`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add options to display images on category blocks on homepage`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add options to display recent blog posts and Instagram on homepage`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Show popular brands on category page sidebar`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add theme options: top header banners, menu categories label, background of main nav`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add Theme Option allow to redirect cart page after add to cart`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add Option to display SKU on product items`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix Apple Pay button`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add new Layout A-Z Table for Brands page`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix duplicated categories button when adding new feature search sidebar`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix compare table missing image when image uniform enabled`}</li>
      <li parentName="ul">{`[`}{`Improve`}{`]`}{` Fix Google snippet to show correct Stock`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add new feature showing button Scroll to Top`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix Product List none option is not checked by default`}</li>
      <li parentName="ul">{`[`}{`Fix`}{`]`}{` Fix facebook like button on product page`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add Feature to show Newsletter Popup`}</li>
      <li parentName="ul">{`[`}{`New`}{`]`}{` Add option number of thumbnail slides on product page`}</li>
    </ul>
    <h2 {...{
      "id": "january-23th-2018---v125"
    }}>{`January 23th, 2018 - v1.2.5`}</h2>
    <ul>
      <li parentName="ul">{`Fix sorting bar gear icon on category page on mobile`}</li>
      <li parentName="ul">{`Fix sidebar margin top when no pages nav or when banner below header enabled`}</li>
      <li parentName="ul">{`Improve better cookie warning style`}</li>
      <li parentName="ul">{`Remove banner style for bottom banner position`}</li>
      <li parentName="ul">{`Fix vertical images carousel glitch when product main image is not the first`}</li>
      <li parentName="ul">{`Fix boxed main carousel not full width`}</li>
      <li parentName="ul">{`Fix main carousel min-height incorrect when categories not expand on homepage`}</li>
      <li parentName="ul">{`Update Akamai image optimization to not optimized for logo and product zoom images`}</li>
      <li parentName="ul">{`Add new feature to allow display videos on homepage carousel`}</li>
    </ul>
    <h3 {...{
      "id": "new-features"
    }}>{`NEW FEATURES:`}</h3>
    <p><img alt="supermarket-1.2.5-new-features" src="https://papathemes.com/product_images/uploaded_images/bc-supermarket-1.2.5-new-features.png" /></p>
    <h2 {...{
      "id": "january-18th-2018---v124"
    }}>{`January 18th, 2018 - v1.2.4`}</h2>
    <ul>
      <li parentName="ul">{`Fix qty box not show on product page on version 1.2.3`}</li>
    </ul>
    <h2 {...{
      "id": "january-17th-2018---v123"
    }}>{`January 17th, 2018 - v1.2.3`}</h2>
    <ul>
      <li parentName="ul">{`Add 40, 100 to number products per brand page, search page`}</li>
      <li parentName="ul">{`Fix bug product options select box's default value reset when others out of stock`}</li>
      <li parentName="ul">{`Fix bug lightbox image won't change when product option is changed`}</li>
      <li parentName="ul">{`Update Yellow style product page image carousel horizontal by default`}</li>
      <li parentName="ul">{`Fix spacing G+ icon`}</li>
      <li parentName="ul">{`Add new feature to support custom tabs on product details`}</li>
      <li parentName="ul">{`Don't display other images when product page loading`}</li>
      <li parentName="ul">{`Fix schema.org Brand when product has no brand`}</li>
      <li parentName="ul">{`Better product card image uniform`}</li>
      <li parentName="ul">{`Move card buttons on image container`}</li>
      <li parentName="ul">{`Add new feature on/off Sticky Header`}</li>
      <li parentName="ul">{`Fix hide qty box if configured in store settings`}</li>
      <li parentName="ul">{`Add new feature showing grid/list, products per page on product page`}</li>
      <li parentName="ul">{`Remove instruction for custom tab`}</li>
      <li parentName="ul">{`Fix setting incorrect ID when product image carousel has no ID`}</li>
      <li parentName="ul">{`Fix login for price on product listing and make it link to login page`}</li>
      <li parentName="ul">{`Fix login for price don't show after login`}</li>
      <li parentName="ul">{`Fix microdata schema when no rating and added image schema`}</li>
      <li parentName="ul">{`Fix product list view type not check show`}{`_`}{`cart`}{`_`}{`action`}</li>
      <li parentName="ul">{`Add option to display 2,3,6 columns on product page`}</li>
      <li parentName="ul">{`Use same template 'grid' for product page list view`}</li>
      <li parentName="ul">{`Add theme option on/off address, phone, colors for top header`}</li>
      <li parentName="ul">{`Add theme option on/off shop by brand, shop by price on sidebar`}</li>
      <li parentName="ul">{`Support custom badges via custom fields, option on/off custom fields `}{`_`}{`_`}</li>
    </ul>
    <h3 {...{
      "id": "new-features-1"
    }}>{`NEW FEATURES:`}</h3>
    <p><img alt="1.2.3 new features" src="https://papathemes.com/product_images/uploaded_images/bc-supermarket-1-2-3-new-features.jpg" /></p>
    <h2 {...{
      "id": "december-21th-2017---v122"
    }}>{`December 21th, 2017 - v1.2.2`}</h2>
    <ul>
      <li parentName="ul">{`Fix main product image not display when product options have image changed`}</li>
      <li parentName="ul">{`Fix banner before description overlaps product details`}</li>
      <li parentName="ul">{`Add feature to support show sub pages on menu`}</li>
      <li parentName="ul">{`Fix CSS issue on popup appear after add to cart`}</li>
      <li parentName="ul">{`Fix product attribute "checkbox" not work`}</li>
      <li parentName="ul">{`Add theme option "product options column" & tweak css on product thumbnail horizontal carousel`}</li>
      <li parentName="ul">{`Move saving price showing before without tax`}</li>
      <li parentName="ul">{`Fix product image height responsive when image uniform enabled`}</li>
      <li parentName="ul">{`Fix product options won't show when open child SKU direct link`}</li>
      <li parentName="ul">{`Fix image lightbox not change when product option is changed`}</li>
    </ul>
    <h2 {...{
      "id": "november-3rd-2017---v121"
    }}>{`November 3rd, 2017 - v1.2.1`}</h2>
    <ul>
      <li parentName="ul">{`Add Number of Products per page 40, 100 in Theme Editor`}</li>
      <li parentName="ul">{`Add new feature allow Vertical categories menu on sidebar auto expand on current category page and auto scroll to current category menu item.`}</li>
      <li parentName="ul">{`Add Theme Option allow to display subcategories grid thumbnails.`}</li>
      <li parentName="ul">{`Fix qty form on product page.`}</li>
      <li parentName="ul">{`Add Theme Option to allow display manual custom links on main menu`}</li>
      <li parentName="ul">{`Fix product thumbnail carousel on quick-view`}</li>
      <li parentName="ul">{`Add Theme Options to support banners below header, on category pages, product pages.`}</li>
      <li parentName="ul">{`Fix main carousel image height for general use`}</li>
      <li parentName="ul">{`Fix products columns show heading when disabled`}</li>
    </ul>
    <h2 {...{
      "id": "october-17th-2017---v120"
    }}>{`October 17th, 2017 - v1.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Add theme options allow to change hover color of dropdown nav links, footer links.`}</li>
      <li parentName="ul">{`Add theme option allow to change colors of Special products tabs.`}</li>
      <li parentName="ul">{`Fix categories list block's heading still show when option set off.`}</li>
      <li parentName="ul">{`Fix vertical subcategories showing wrong`}</li>
      <li parentName="ul">{`Fix problem when enter on quantity box won't decrease 1 unit`}</li>
      <li parentName="ul">{`Fix problem when manually input quality input on the cart page don't update`}</li>
      <li parentName="ul">{`Add theme option to support showing horizontal slider thumbnails below the main image on product page.`}</li>
      <li parentName="ul">{`Remove space at bottom of subcategories on vertical menu`}</li>
      <li parentName="ul">{`Fix restore main image when product option has no custom image is selected on product page`}</li>
    </ul>
    <h2 {...{
      "id": "september-28th-2017---v111"
    }}>{`September 28th, 2017 - v1.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Fix search results overlap sidebar on Yellow style.`}</li>
      <li parentName="ul">{`Remove facebook block by default.`}</li>
      <li parentName="ul">{`Add theme option to make product images uniform.`}</li>
      <li parentName="ul">{`Update Roboto fonts for Yellow style.`}</li>
    </ul>
    <h2 {...{
      "id": "september-27th-2017---v110"
    }}>{`September 27th, 2017 - v1.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add new layout Left Sidebar across the whole site`}</li>
      <li parentName="ul">{`Style better sidebar block`}</li>
      <li parentName="ul">{`Update smaller categories menu`}</li>
      <li parentName="ul">{`Update sidebar display below content on mobile`}</li>
      <li parentName="ul">{`Update product filter on brand page, search page on mobile`}</li>
      <li parentName="ul">{`Fix a styling glitch on block 'shop by price'`}</li>
    </ul>
    <h2 {...{
      "id": "august-8th-2017---v108"
    }}>{`August 8th, 2017 - v1.0.8`}</h2>
    <ul>
      <li parentName="ul">{`Initial release on theme store.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      